/* global Component */
class OrderPayment extends Component {

    getProps() {
        return ['orderSerNr'];
    }

    data() {
        return {
            appIsReady:false,
            isLoadingInfo:true,
            orderIsPayment:false,
            mpdata : {},
            errorMsg:null,
            paymentMsg:null,
            paymentIsProcess:false,
            SOPayTerm: null,
        };
    }

    static componentName() {
        return 'orderPaymentPage';
    }

    getRoutes() {
        return {};
    }

    getWatch() {
        return {
            '$store.getters.getAppState':function (param1,param2){
                if(this.$store.getters.getAppState == 'ready'){
                    if(this.$store.state.session) {
                        this.loadPaymentData();
                        this.appIsReady = true;
                    }
                    else{
                        this.$store.dispatch('setRedirectLogin',this.$route.fullPath);
                        this.$router.push({name:'LoginLink'});
                    }
                }
            }
        };
    }

    mounted(){
        return async function () {
            if(this.$store.getters.getAppState == 'ready'){
                this.loadPaymentData();
                this.appIsReady = true;
            }
        };
    }

    orderSerNr(){
        // console.log('computed SerNr');
    }

    getPayTermObj(){
        return this.$store.state.setting.PayTermRow.find(row => row.fields.PayTermCode === this.SOPayTerm);
    }

    getMethods(){
        return {
            loadPaymentData:this.loadPaymentData,
            paymentProcess:this.paymentProcess,
            paymentInit:this.paymentInit,
            getPayTermObj: this.getPayTermObj
        };
    }

    loadPaymentData(){
        this.isLoadingInfo = true;
        if(!this.$store.state.screenState)
            this.$store.dispatch('toogleLockScreen',null);
        this.$store.dispatch("loadPaymentData",{SerNr:this.soSerNr}).then(response=>{
            //console.log('Component Load Payment',response);
            if(! response.isPayment && this.soSerNr == response.so.fields.SerNr) {
                this.orderIsPayment = response.isPayment;
                let mpdata = {};
                mpdata.email = "";
                mpdata.salesorder = this.soSerNr;
                mpdata.amount = response.total;
                this.mpdata = mpdata;
                this.SOPayTerm = response.so.fields.PayTerm;
            }
            else{
                this.orderIsPayment = true;
                if(response.errors)
                    this.errorMsg = response.errors;
                else
                    this.errorMsg = response.salesorder;
            }
            this.$store.dispatch('toogleLockScreen', "");
            this.isLoadingInfo = false;
        });
    }

    paymentProcess(event){
        this.paymentMsg = [];
        this.$store.dispatch('toogleLockScreen', "");
        if(event.actionResult && event.paymentResult.ok) {
            this.paymentIsProcess = true;
            this.paymentMsg = null;
            this.$store.dispatch("approvedOrder",this.soSerNr);
        }
        else{
            this.orderIsPayment = false;
            if(event.cause)
                this.paymentMsg = event.cause;
            if(event.paymentResult && event.paymentResult.message) {
                this.paymentMsg = event.paymentResult.message;
            }
            if (event.hasOwnProperty("paymentResult")){
                this.error = true;
                if(event.paymentResult.error && event.paymentResult.error.message)
                    this.paymentMsg = event.paymentResult.error.message;
                else {
                    if (event.paymentResult.message)
                        this.paymentMsg = event.paymentResult.message;
                }
            }
            window.scrollTo(0,0);
        }
    }

    paymentInit(){
        this.$store.dispatch('toogleLockScreen',this.tr('Payment Processing'));
    }

    getComputed() {
        return {
            soSerNr:function (){
                if (this.$route.params.orderid)
                    return atob(this.$route.params.orderid);
                return this.orderSerNr;
            }
        };
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer" >
                      <div class="section-main col-lg-8 offset-lg-2" v-if="appIsReady">
                           <h5 class="text-center my-4">{{tr("Payment")}} {{tr('Sales Order')}} <strong>{{soSerNr}}</strong></h5>
                           <template  v-if="!isLoadingInfo">
                              <template v-if="orderIsPayment">
                                 <div v-if="errorMsg" class="alert alert-warning"  role="alert">
                                     <h5 class="text-center">{{tr("Information")}}</h5>
                                     <template v-if="Array.isArray(errorMsg)">
                                        <h6 v-for="msg_error of errorMsg" class="text-center">{{tr([msg_error.code,...msg_error.params.params])}}</h6>
                                    </template>
                                    <template v-else>
                                        <h6 class="text-center">{{tr(errorMsg)}}</h6>
                                    </template>
                                 </div>
                                 <div class="row justify-content-center mt-4" >
                                      <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                 </div>
                              </template>
                              <template v-else>
                                   <div class="col-md-6 offset-md-3 alert alert-warning" role="alert" v-if="paymentMsg">
                                         <h5 class="text-center">{{tr("Information")}}</h5>
                                         <template v-if="Array.isArray(paymentMsg)">
                                            <p v-for="error of paymentMsg" class="text-center">{{tr(error.description.charAt(0).toUpperCase() + error.description.slice(1))}}</p>
                                        </template>
                                        <template v-else>
                                            <p class="text-center">{{tr(paymentMsg)}}</p>
                                        </template>
                                        <div class="row justify-content-center mt-4"  v-if="paymentIsProcess">
                                           <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                        </div>
                                   </div>
                                    <template v-if="paymentIsProcess">
                                        <div class="col-12 col-md-6 offset-md-3 alert alert-success text-center" role="alert">
                                            <icon name="regular/check-circle" scale="5" class="col-6"/>
                                            <h4 class="col-12 text-center mt-2">{{tr("The payment was approved successfully")}}</h4>
                                            <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                        </div>
                                        <div class="row justify-content-center mt-4">
                                           <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                        </div>
                                    </template>
                                    <div class="row justify-content-center mt-4"  v-else>
                                        <template v-if="$store.getters.getSetting.MercadoPagoMode==1">
                                            <mercadopagoFormCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoFormCustom>
                                        </template>
                                        <template v-else-if='$store.getters.getSetting.MercadoPagoMode==0'>
                                            <mercadopagoCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoCustom>
                                        </template>
                                        <template v-else-if='$store.getters.getSetting.MercadoPagoMode==2'>
                                            <redirectPaymentComponent :SO='soSerNr' :PayTermObj='getPayTermObj()'/>
                                        </template>
                                    </div>
                              </template>
                           </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;

    }
}

OrderPayment.registerComponent();